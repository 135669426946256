import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux'
import {Modal,Form,Button,Row,Col,Container} from 'react-bootstrap';
import {update_publishers_Data} from '../actions/PublishersActions'




 
const EditForm = (props) =>{
  const [formValue,setFormValue] = useState({});
  
  


    const {
        show,
        handleClose,
        editId,
    }=props;


    const closeHandle=()=>{
      handleClose();
      resetFormValue();
      
    }
    const resetFormValue = () =>{
      setFormValue({});
    }
    const dispatch = useDispatch();
    
    const submitHandler = ( data,id) =>{

      dispatch(update_publishers_Data(data,id));
      resetFormValue();
      closeHandle();

    }
  
  





    return(
        <Modal 
            show={show} 
            onHide={closeHandle}
            size="lg"
            centered
            style={{backgroundColor:'rgba(0, 204, 255,0.7)'}}
            >
              <Modal.Header closeButton>
          <Modal.Title>Publisher</Modal.Title>
        </Modal.Header>
        <Modal.Body>  
        <Form >
          <Container>
          {console.log(formValue)}
         {/* {console.log(print)} */}
        <Row>
      <Col sm={6}>
      <Form.Label >
        <b>Name</b>
      </Form.Label>
      {editId!==null?(editId.name===""?
      (
        <Form.Control type="text" placeholder="Name"  onChange={(e)=>{setFormValue({...formValue,name:e.target.value})
        
      
      }}/>
      )
      :
      (
        <Form.Control type="text" value={formValue.name===undefined?editId.name:formValue.name}  onChange={(e)=>{setFormValue({...formValue,name:e.target.value})
        
      
      }}/>
      )

      ):null

      }
        </Col><br/>
        
        <Col sm={6}>
      <Form.Label >
        <b>Address</b>
      </Form.Label>
      {editId!==null?(editId.address===""?
      (
        <Form.Control type="text" placeholder="Name"  onChange={(e)=>{setFormValue({...formValue,address:e.target.value})
        
      
      }}/>
      )
      :
      (
        <Form.Control type="text" value={formValue.address===undefined?editId.address:formValue.address}  onChange={(e)=>{setFormValue({...formValue,address:e.target.value})
        
      
      }}/>
      )

      ):null

      }
        </Col><br/>
        <Col sm={6}>
      <Form.Label >
        <b>Mobile No</b>
      </Form.Label>
      {editId!==null?(editId.mobile_no===""?
      (
        <Form.Control type="text" placeholder="Mobile No"  onChange={(e)=>{setFormValue({...formValue,mobile_no:e.target.value})
        
      
      }}/>
      )
      :
      (
        <Form.Control type="text" value={formValue.mobile_no===undefined?editId.mobile_no:formValue.mobile_no}  onChange={(e)=>{setFormValue({...formValue,mobile_no:e.target.value})
        
      
      }}/>
      )

      ):null

      }
        </Col><br/>
        <Col sm={6}>
      <Form.Label >
        <b>E-mail Address</b>
      </Form.Label>
      {editId!==null?(editId.email_address===""?
      (
        <Form.Control type="text" placeholder="E-mail Address"  onChange={(e)=>{setFormValue({...formValue,email_address:e.target.value})
        
      
      }}/>
      )
      :
      (
        <Form.Control type="text" value={formValue.email_address===undefined?editId.email_address:formValue.email_address}  onChange={(e)=>{setFormValue({...formValue,email_address:e.target.value})
        
      
      }}/>
      )

      ):null

      }
        </Col><br/>
      
      <Col>
    <Form.Group  >
      <Form.Label >
        <b>Published Status</b>
      </Form.Label>
      
      <Col className="d-flex" onChange={(e)=>{setFormValue({...formValue,status:e.target.value})
      // editId.status=e.target.value;
        
    
    
    }}  >
         
        {editId===null?null:((formValue.status===undefined?editId.status:formValue.status)==="A"?
        ( <Form.Check
          type="radio"
          label="YES"
          name="formHorizontalRadios"
          id="formHorizontalRadios1"
          value="A"
          checked
        /> ):( <Form.Check
          type="radio"
          label="YES"
          name="formHorizontalRadios"
          id="formHorizontalRadios1"
          value="A"
          
        /> ))}
         &nbsp;&nbsp;
         {editId===null?null:((formValue.status===undefined?editId.status:formValue.status)==="N"?
        ( <Form.Check
          type="radio"
          label="NO"
          name="formHorizontalRadios"
          id="formHorizontalRadios1"
          value="N"
          checked
        /> ):( <Form.Check
          type="radio"
          label="NO"
          name="formHorizontalRadios"
          id="formHorizontalRadios1"
          value="N"
          
        /> ))}
        
      </Col>
    </Form.Group>
    </Col>
   
    
    </Row>
    </Container>

        </Form>   
        </Modal.Body>

            <Modal.Footer>
            <Button variant="warning" onClick={closeHandle}>Cancel</Button>
      <Button variant="info" type="submit" onClick={()=>submitHandler(formValue,editId.id)}>Submit</Button>

        </Modal.Footer>
        
       {console.log('Edit ID:',editId)}
       {console.log('Status:',formValue.status)}

            </Modal>



    )
}

export default EditForm;