import React from 'react';
import {Navbar,Button,NavDropdown,Nav} from 'react-bootstrap';
import { BrowserRouter as Router,Switch,Route,Link} from "react-router-dom";
import { useState } from 'react';
import HomePage from '../HomePage'
// import TestComponent from './TestComponent'
import  SideBar  from '../SideBar';
import Publishers from '../publishers/Publishers'
import Events from '../events/Events'
import { GiHamburgerMenu } from "react-icons/gi";
import NoticeAreas from '../noticeareas/NoticeAreas';
import notice_categories from '../reducers/NoticeCategoriesReducer';
import NoticeCategories from '../noticecategories/NoticeCategories';
import Notices from '../notices/Notices';
import {useDispatch} from 'react-redux'
import {AUTH_UPDATE} from '../constants/AuthenticationConstants'

const MainApp = () =>{

    const [open, setOpen] = useState(true);
    const [optionName, setOptionName] = useState('DASHBOARD');
    const dispatch = useDispatch();



    return(
    <>
    <Router>
      <div style={{display:'flex'}} >
      
        <SideBar open={open} optionName={optionName} setOptionName={setOptionName} />
     

    <div className="w-100" style={{height:'100vh'}}>
   
        <Navbar style={{backgroundColor:'skyblue'}}>
       
        <Navbar.Brand >
        <h2>
          < GiHamburgerMenu onClick={()=>{setOpen(!open)}} style={{cursor:'pointer'}}/>
        {/* <Button variant="info" size="lg" onClick={()=>{setOpen(!open)}}><BsReverseLayoutTextSidebarReverse/> Menu</Button> */}
        </h2>
        
        </Navbar.Brand>
        <Nav className="justify-content-end ml-auto" >
        <NavDropdown alignRight className="ml-auto" title="Admin" id="nav-dropdown">
        <NavDropdown.Item eventKey="4.1" onClick={()=>{localStorage.removeItem('token');dispatch(
            {
                type:AUTH_UPDATE,
                payload:false

            }
            )}}>logout</NavDropdown.Item>
        <NavDropdown.Divider />
        
        {/* <NavDropdown.Item eventKey="4.4">Separated link</NavDropdown.Item> */}
      </NavDropdown>
   
    </Nav>
        </Navbar>
      
        <Switch>
          
          
          <Route exact path="/">
            <HomePage/>
          </Route>
         
         
          <Route path="/publishers">
            <Publishers/>
          </Route>
          <Route path="/noticeareas">
            <NoticeAreas/>
          </Route>
          <Route path="/noticecategories">
            <NoticeCategories/>
          </Route>
          <Route path="/notices">
            <Notices/>
          </Route>
          <Route path="/events">
            <Events/>
          </Route>
         
         
        </Switch>
       
        </div>
      </div>
      </Router>

    </>
    )
}

export default MainApp;