import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux'
import {Modal,Form,Button,Row,Col,Container} from 'react-bootstrap';

import {delete_notice_categories_Data} from '../actions/NoticeCategoriesActions'




 
const DeleteForm = (props) =>{
 
  const {
    show,
    handleClose,
    editId,
    }=props;

    const dispatch = useDispatch();

    return(
      <Modal show={show} style={{backgroundColor:'rgba(0, 204, 255,0.7)'}} centered>
      <Modal.Body>Do you want to delete?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={()=>{dispatch(delete_notice_categories_Data(editId.id));handleClose()}}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>



    )
}

export default DeleteForm;