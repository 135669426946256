import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux'
import {Modal,Form,Button,Row,Col,Container} from 'react-bootstrap';
import {update_notices_Data} from '../actions/NoticesActions'
import {get_notice_categories_data} from '../actions/NoticeCategoriesActions';
import {get_publishers_data} from '../actions/PublishersActions';
import {get_notice_areas_data} from '../actions/NoticeAreasActions'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


 
const EditForm = (props) =>{
  const [formValue,setFormValue] = useState({});
  const [publishDate, setPublishDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [noticeImage1,setNoticeImage1] = useState(null);

  const [noticeImage2,setNoticeImage2] = useState(null);

  const [noticeImage3,setNoticeImage3] = useState(null);
  const formData = new FormData();


    const {
        show,
        handleClose,
        editId,
    }=props;
    const closeHandle=()=>{
      handleClose();
      resetFormValue();
      
    }
    const resetFormValue = () =>{
      setFormValue({});
      setNoticeImage1(null);
      setNoticeImage2(null);
      setNoticeImage3(null);
    }
    const dispatch = useDispatch();
 
    const publisher = useSelector((state)=>state.publisher.publishers);
    const categories = useSelector((state)=>state.categories.notice_categories);
    const area = useSelector((state)=>state.areas.notice_areas);
    
    
  
  useEffect(()=>{
    
    dispatch(get_publishers_data());
    dispatch(get_notice_categories_data());
    dispatch(get_notice_areas_data());
  },[dispatch])



  const submitHandler = () =>{



    if(noticeImage1!==null){
      formData.append("notice_image_1",noticeImage1,noticeImage1.name);
      }
    
    if(noticeImage2!==null){
      formData.append("notice_image_2",noticeImage2,noticeImage2.name);
      }
    
    if(noticeImage3!==null){
      formData.append("notice_image_3",noticeImage3,noticeImage3.name);
      }

    if(formValue.publisher_id!=undefined){
      formData.append("publisher_id",formValue.publisher_id);
      }

    if(formValue.notice_category_id!=undefined){
      formData.append("notice_category_id",formValue.notice_category_id);
      }

    if(formValue.notice_area_id!=undefined){
      formData.append("notice_area_id",formValue.notice_area_id);
      }
        
    if(formValue.end_date!=undefined){
      formData.append("end_date",formValue.end_date);
      }
          
    if(formValue.bill_amt!=undefined){
      formData.append("bill_amt",formValue.bill_amt);
      }

    if(formValue.paid_amt!=undefined){
      formData.append("paid_amt",formValue.paid_amt);
      }

    if(formValue.published_status!=undefined){
      formData.append("published_status",formValue.published_status);
      }

    if(formValue.featured!=undefined){
      formData.append("featured",formValue.featured);
      }
      
    if(formValue.notice!=undefined){
        formData.append("notice",formValue.notice);
        }

    
    dispatch(update_notices_Data(formData,editId.id));
    resetFormValue();
    handleClose();

  }




    return(
        <Modal 
            show={show} 
            onHide={closeHandle}
            size="lg"
            centered
            style={{backgroundColor:'rgba(0, 204, 255,0.7)'}}
            >
              <Modal.Header closeButton>
          <Modal.Title>Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body>  
        <Form >
          <Container>
          {console.log(formValue)}
         {/* {console.log(print)} */}
        <Row>
        <Col sm={4}>
      <Form.Label >
        <b>Publisher</b>
      </Form.Label>
      <Form.Control as="select" defaultValue={editId?editId.publisher_id:null} onChange={(e)=>setFormValue({...formValue,publisher_id:e.target.value})}>
       {
         publisher?(publisher.map((item)=>{
          
           return <option key={item.id} value={item.id}>{item.name}</option>

         }))
         :
         null
       } 
       {/* <select>
      
</select> */}

      </Form.Control>
      
      </Col><br/><br/><br/><br/>
     
      <Col sm={4}>
      <Form.Label >
        <b>Category</b>
      </Form.Label>
      <Form.Control as="select" defaultValue={editId?editId.notice_category_id:null} onChange={(e)=>setFormValue({...formValue,notice_category_id:e.target.value})}>
       {
         categories?(categories.map((item)=>{
           return <option key={item.id} value={item.id}>{item.notice_category}</option>

         }))
         :
         null
       } 
       {/* <select>
      
</select> */}

      </Form.Control>
      
      </Col>
     

      <Col sm={4}>
      <Form.Label >
        <b>Area</b>
      </Form.Label>
      <Form.Control as="select" defaultValue={editId?editId.notice_area_id:null} onChange={(e)=>setFormValue({...formValue,notice_area_id:e.target.value})}>
       {
         area?(area.map((item)=>{
           return <option key={item.id} value={item.id}>{item.area}</option>

         }))
         :
         null
       } 
       {/* <select>
      
</select> */}

      </Form.Control>
      
      </Col>

      
      <Col sm={4}>
      <Form.Label >
        <b>Notice Image</b>
      </Form.Label>
        <Form.Group>
      <Form.File id="categor_icon" onChange={(e)=>{setNoticeImage1(e.target.files[0])}} />
        </Form.Group>
        </Col>

        <Col sm={4}>
      <Form.Label >
        <b>Notice Image</b>
      </Form.Label>
        <Form.Group>
      <Form.File id="categor_icon" onChange={(e)=>{setNoticeImage2(e.target.files[0])}} />
        </Form.Group>
        </Col>

        <Col sm={4}>
      <Form.Label >
        <b>Notice Image</b>
      </Form.Label>
        <Form.Group>
      <Form.File id="categor_icon" onChange={(e)=>{setNoticeImage3(e.target.files[0])}} />
        </Form.Group>
        </Col>
   
     





      
      <Col sm={4}>
      <Form.Label >
        <b>Bill Amount</b>
      </Form.Label>
        <Form.Control type="text" defaultValue={editId?editId.bill_amt:null} placeholder="Chapter Title"  onChange={(e)=>setFormValue({...formValue,bill_amt:e.target.value})}/></Col><br/><br/><br/><br/>
      
     
        <Col sm={4}>
      <Form.Label >
      <b>End Date</b>
      </Form.Label><br/>

        <DatePicker selected={formValue.end_date===undefined?new Date(editId?editId.end_date:null):new Date(formValue.end_date)} onChange={date => {setEndDate(date); setFormValue({...formValue,end_date:`${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`})}} />

        
        </Col>



        <Col sm={4}>
      <Form.Label >
      <b>Paid Amount</b>
      </Form.Label>
        <Form.Control type="text" defaultValue={editId?editId.paid_amt:null} placeholder="paid amount"  onChange={(e)=>setFormValue({...formValue,paid_amt:e.target.value})}/></Col><br/><br/><br/><br/>


     
      <Col>
    <Form.Group  >
      <Form.Label >
        <b>Published Status</b>
      </Form.Label>
      
      <Col className="d-flex" onChange={(e)=>{setFormValue({...formValue,published_status:e.target.value})
      editId.published_status=e.target.value;
    
    
    }}  >
         
        {editId===null?null:(editId.published_status==="A"?
        ( <Form.Check
          type="radio"
          label="YES"
          name="formHorizontalRadios"
          id="formHorizontalRadios1"
          value="A"
          checked
        /> ):( <Form.Check
          type="radio"
          label="YES"
          name="formHorizontalRadios"
          id="formHorizontalRadios1"
          value="A"
          
        /> ))}
         &nbsp;&nbsp;
         {editId===null?null:(editId.published_status==="N"?
        ( <Form.Check
          type="radio"
          label="NO"
          name="formHorizontalRadios"
          id="formHorizontalRadios1"
          value="N"
          checked
        /> ):( <Form.Check
          type="radio"
          label="NO"
          name="formHorizontalRadios"
          id="formHorizontalRadios1"
          value="N"
          
        /> ))}
        
      </Col>
    </Form.Group>
    </Col>

    <Col>
    <Form.Group  >
      <Form.Label >
        <b>Featured</b>
      </Form.Label>
      
      <Col className="d-flex" onChange={(e)=>{setFormValue({...formValue,featured:e.target.value})
      editId.featured=e.target.value;
    
    
    }}  >
         
        {editId===null?null:(editId.featured==="Y"?
        ( <Form.Check
          type="radio"
          label="YES"
          name="formHorizontalRadios1"
          id="formHorizontalRadios1"
          value="Y"
          checked
        /> ):( <Form.Check
          type="radio"
          label="YES"
          name="formHorizontalRadios1"
          id="formHorizontalRadios1"
          value="Y"
          
        /> ))}
         &nbsp;&nbsp;
         {editId===null?null:(editId.featured==="N"?
        ( <Form.Check
          type="radio"
          label="NO"
          name="formHorizontalRadios1"
          id="formHorizontalRadios1"
          value="N"
          checked
        /> ):( <Form.Check
          type="radio"
          label="NO"
          name="formHorizontalRadios1"
          id="formHorizontalRadios1"
          value="N"
          
        /> ))}
        
      </Col>
    </Form.Group>
    </Col>
    <Col sm={12}>
      <Form.Label >
        <b>Notice</b>
      </Form.Label>
      <Form.Control as="textarea" defaultValue={editId?editId.notice:null} rows={4} placeholder="Chapter No." onChange={(e)=>setFormValue({...formValue,notice:e.target.value})} name="id"/>
      </Col>

    
    </Row>
    </Container>

        </Form>   
        </Modal.Body>

            <Modal.Footer>
            <Button variant="warning" onClick={closeHandle}>Cancel</Button>
      <Button variant="info" type="submit" onClick={()=>submitHandler()}>Submit</Button>

        </Modal.Footer>
        
       {console.log('Edit ID:',editId)}
            </Modal>



    )
}

export default EditForm;