// import './App.css';
import MainApp from './app/MainApp'
import {useDispatch} from 'react-redux'
import { BrowserRouter as Router,Switch,Route,Link,Redirect} from "react-router-dom";
import PrivateRoute from './app/PrivateRoute'
import LoginPage from './app/LoginPage'
import {useSelector} from 'react-redux';
import {user_get} from './actions/AuthenticationActions'
import { useEffect } from 'react';
 




function App() {

const auth = useSelector((state)=>state.isAuth.auth);
const dispatch = useDispatch();

// const Testing = () =>{
//   console.log('checkingauth...')
//   console.log('Checking...')
//   // localStorage.removeItem('token');

//   if(localStorage.getItem("token")){console.log(localStorage.getItem("token"))
//   dispatch(user_get(localStorage.getItem("token")));
// }
// }
useEffect(()=>{
  if(localStorage.getItem("token")){console.log(localStorage.getItem("token"))
  dispatch(user_get(localStorage.getItem("token")));
}
},[dispatch])
  return (
    <Router>
    <div className="App">
    <Switch>
          
          
          <Route exact path="/login">
            {/* {Testing()} */}
          {
          
          auth? <Redirect to="/" /> : <LoginPage/>
          
          }
         
          
          </Route>
          <PrivateRoute path="/">
        <MainApp/>
          </PrivateRoute>
          
        </Switch>


        {/* <Image/> */}


      {/* <TestComponent/> */}
    </div>
    </Router>
  );
}

export default App;
