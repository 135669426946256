import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux'
import {Modal,Form,Button,Row,Col,Container} from 'react-bootstrap';
import {update_notice_areas_Data} from '../actions/NoticeAreasActions'




 
const EditForm = (props) =>{
  const [formValue,setFormValue] = useState({});
  const [area,setArea] = useState("");
  const [country,setCountry] = useState("");

  

    const {
        show,
        handleClose,
        editId,
    }=props;
  
    const closeHandle=()=>{
      handleClose();
      resetFormValue();
      
    }
    const resetFormValue = () =>{
      setFormValue({});
      setArea("");
    }
    const dispatch = useDispatch();
    
    
  






    return(
        <Modal 
            show={show} 
            onHide={closeHandle}
            size="lg"
            centered
            style={{backgroundColor:'rgba(0, 204, 255,0.7)'}}
            >
              <Modal.Header closeButton>
          <Modal.Title>Notice Area</Modal.Title>
        </Modal.Header>
        <Modal.Body>  
        <Form >
          <Container>
          {console.log(formValue)}
         {/* {console.log(print)} */}
        <Row>

     

      <br/>
      <Col sm={6}>
      <Form.Label >
        <b>Country</b>
      </Form.Label>
      {editId!==null?(editId.country===""?
      (
        <Form.Control type="text" placeholder="Chapter Title"  onChange={(e)=>{setFormValue({...formValue,country:e.target.value})
        editId.chapter_no = e.target.value;
      
      }}/>
      )
      :
      (
        <Form.Control type="text" defaultValue={country===""?(editId.country):country}  onChange={(e)=>{setFormValue({...formValue,country:e.target.value})
        // editId.country = e.target.value;
        setCountry(e.target.value);
      }}/>
      )

      ):null

      }
        </Col><br/>
        
      <Col sm={6}>
      <Form.Label >
        <b>Area</b>
      </Form.Label>
      {editId!==null?(editId.area===""?
      (
        <Form.Control type="text" placeholder="Chapter Title"  onChange={(e)=>{setFormValue({...formValue,area:e.target.value})
        editId.area = e.target.value;
      
      }}/>
      )
      :
      (
        <Form.Control type="text" defaultValue={area===""?(editId.area):area}  onChange={(e)=>{setFormValue({...formValue,area:e.target.value})
        setArea(e.target.value);
        // editId.area = e.target.value;
      
      }}/>
      )

      ):null

      }
        </Col><br/>
       
     
   
   
    
    </Row>
    </Container>

        </Form>   
        </Modal.Body>

            <Modal.Footer>
            <Button variant="warning" onClick={closeHandle}>Cancel</Button>
      <Button variant="info" type="submit" onClick={()=>{dispatch(update_notice_areas_Data(formValue,editId.id));closeHandle()}}>Submit</Button>

        </Modal.Footer>
        
       {console.log('Edit ID:',editId)}
            </Modal>



    )
}

export default EditForm;