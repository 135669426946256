
const publishers = (state={},{type,payload}) =>{

    switch(type){
        case 'PUBLISHERS_RECIEVE':
            return {...state,publishers:payload}
        default:
            return {...state}
    }

}

export default publishers;