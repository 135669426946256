
class Auth{
    constructor(){
        this.login = true;
        }
        store(data){
           
             localStorage.setItem("authData", data.access_token);
            this.login = true;
            
        }
        remove(){
            localStorage.removeItem("authData");
        }
        signin() { 
            this.login = true;
            return this.isAuth();
        }
        signOut() { 
            this.login = false;
            return this.isAuth();
        }
        isAuth(){
            return this.login;
        }
    
}

export default new Auth();