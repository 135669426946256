import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux'
import {Modal,Form,Button,Row,Col,Container} from 'react-bootstrap';
import {get_notice_categories_data} from '../actions/NoticeCategoriesActions';
import {get_publishers_data} from '../actions/PublishersActions';
import {create_notices_Data} from '../actions/NoticesActions'
import {get_notice_areas_data} from '../actions/NoticeAreasActions'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

 
const CreateForm = (props) =>{


  const [endDate, setEndDate] = useState(new Date());
  const [noticeImage1,setNoticeImage1] = useState(null);

  const [noticeImage2,setNoticeImage2] = useState(null);

  const [noticeImage3,setNoticeImage3] = useState(null);


 
  const [formValue,setFormValue] = useState({});
  const formData = new FormData();

    const {
        show,
        handleClose,
    }=props;
    const closeHandle=()=>{
      handleClose();
      resetFormValue();
      
    }
    const resetFormValue = () =>{
      setFormValue({});
      setNoticeImage1(null);
      setNoticeImage2(null);
      setNoticeImage3(null);
    }
    const dispatch = useDispatch();
    const publisher = useSelector((state)=>state.publisher.publishers);
    const categories = useSelector((state)=>state.categories.notice_categories);
    const area = useSelector((state)=>state.areas.notice_areas);


    // console.log('Frome Chapter Create Form',complianceLaw);
  
  useEffect(()=>{


    dispatch(get_publishers_data());
    dispatch(get_notice_categories_data());
    dispatch(get_notice_areas_data());

    
  },[dispatch])

  const submitHandler = () =>{



    if(noticeImage1!==null){
      formData.append("notice_image_1",noticeImage1,noticeImage1.name);
      }
    
    if(noticeImage2!==null){
      formData.append("notice_image_2",noticeImage2,noticeImage2.name);
      }
    
    if(noticeImage3!==null){
      formData.append("notice_image_3",noticeImage3,noticeImage3.name);
      }

    if(formValue.publisher_id!=undefined){
      formData.append("publisher_id",formValue.publisher_id);
      }

    if(formValue.notice_category_id!=undefined){
      formData.append("notice_category_id",formValue.notice_category_id);
      }

    if(formValue.notice_area_id!=undefined){
      formData.append("notice_area_id",formValue.notice_area_id);
      }
        
    if(formValue.end_date!=undefined){
      formData.append("end_date",formValue.end_date);
      }
          
    if(formValue.bill_amt!=undefined){
      formData.append("bill_amt",formValue.bill_amt);
      }

    if(formValue.paid_amt!=undefined){
      formData.append("paid_amt",formValue.paid_amt);
      }

    if(formValue.published_status!=undefined){
      formData.append("published_status",formValue.published_status);
      }

    if(formValue.featured!=undefined){
      formData.append("featured",formValue.featured);
      }
      
    if(formValue.notice!=undefined){
        formData.append("notice",formValue.notice);
        }

    dispatch(create_notices_Data(formData));
    resetFormValue();
    handleClose();
    

  }




    return(
        <Modal 
            show={show} 
            onHide={closeHandle}
            size="lg"
            centered
            style={{backgroundColor:'rgba(0, 204, 255,0.7)'}}
            >
              <Modal.Header closeButton>
          <Modal.Title>Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body>  
        <Form>
          <Container>
          {console.log(formValue)}
         {/* {console.log(print)} */}
      <Row>
      <Col sm={4}>
      <Form.Label >
        <b>Publisher</b>
      </Form.Label>
      <Form.Control as="select" onChange={(e)=>setFormValue({...formValue,publisher_id:e.target.value})}>
      <option>...</option>
       {
         publisher?(publisher.map((item)=>{
          
           return <option key={item.id} value={item.id}>{item.name}</option>

         }))
         :
         null
       } 
       {/* <select>
      
</select> */}

      </Form.Control>
      
      </Col><br/><br/><br/><br/>

      <Col sm={4}>
      <Form.Label >
        <b>Category</b>
      </Form.Label>
      <Form.Control as="select" onChange={(e)=>setFormValue({...formValue,notice_category_id:e.target.value})}>
      <option>...</option>
       {
         categories?(categories.map((item)=>{
           return <option key={item.id} value={item.id}>{item.notice_category}</option>

         }))
         :
         null
       } 
       {/* <select>
      
</select> */}

      </Form.Control>
      
      </Col>
      <Col sm={4}>
      <Form.Label >
        <b>Area</b>
      </Form.Label>
      <Form.Control as="select" onChange={(e)=>setFormValue({...formValue,notice_area_id:e.target.value})}>
      <option>...</option>
       {
         area?(area.map((item)=>{
           return <option key={item.id} value={item.id}>{item.area}</option>

         }))
         :
         null
       } 
       {/* <select>
      
</select> */}

      </Form.Control>
      
      </Col><br/><br/><br/><br/>
    
     
      <Col sm={4}>
      <Form.Label >
        <b>Notice Image</b>
      </Form.Label>
        <Form.Group>
      <Form.File id="categor_icon" onChange={(e)=>{setNoticeImage1(e.target.files[0])}} />
        </Form.Group>
        </Col>

        <Col sm={4}>
      <Form.Label >
        <b>Notice Image</b>
      </Form.Label>
        <Form.Group>
      <Form.File id="categor_icon" onChange={(e)=>{setNoticeImage2(e.target.files[0])}} />
        </Form.Group>
        </Col>

        <Col sm={4}>
      <Form.Label >
        <b>Notice Image</b>
      </Form.Label>
        <Form.Group>
      <Form.File id="categor_icon" onChange={(e)=>{setNoticeImage3(e.target.files[0])}} />
        </Form.Group>
        </Col>
   
     

      <br/>
      <Col sm={4}>
      <Form.Label >
        <b>Bill Amount</b>
      </Form.Label>
        <Form.Control type="text" placeholder="bill amount"  onChange={(e)=>setFormValue({...formValue,bill_amt:e.target.value})}/></Col><br/><br/><br/><br/>
        
        <Col sm={4}>
      <Form.Label >
      <b>Paid Amount</b>
      </Form.Label>
        <Form.Control type="text" placeholder="paid amount"  onChange={(e)=>setFormValue({...formValue,paid_amt:e.target.value})}/></Col><br/><br/><br/><br/>



        <Col sm={4}>
      <Form.Label >
      <b>End Date</b>
      </Form.Label><br/>

        <DatePicker selected={endDate} onChange={date => {setEndDate(date); setFormValue({...formValue,end_date:`${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`})}} />

        
        </Col>



      
       <Col>
    <Form.Group  >
      <Form.Label >
        <b>Published Status</b>
      </Form.Label>
      <Col className="d-flex" onChange={(e)=>setFormValue({...formValue,published_status:e.target.value})} >
        <Form.Check
          type="radio"
          label="YES"
          name="formHorizontalRadios"
          id="formHorizontalRadios1"
          value="A"
        /> &nbsp;&nbsp;
        <Form.Check
          type="radio"
          label="NO"
          name="formHorizontalRadios"
          id="formHorizontalRadios2"
          value="N"
        />
      </Col>
    </Form.Group>
    </Col>

    <Col>
    <Form.Group  >
      <Form.Label >
        <b>Featured Status</b>
      </Form.Label>
      <Col className="d-flex" onChange={(e)=>setFormValue({...formValue,featured:e.target.value})} >
        <Form.Check
          type="radio"
          label="YES"
          name="formHorizontalRadios1"
          id="formHorizontalRadios"
          value="Y"
        /> &nbsp;&nbsp;
        <Form.Check
          type="radio"
          label="NO"
          name="formHorizontalRadios1"
          id="formHorizontalRadios2"
          value="N"
        />
      </Col>
    </Form.Group>
    </Col>
    <Col sm={12}>
      <Form.Label >
        <b>Notice</b>
      </Form.Label>
      <Form.Control as="textarea" rows={4} placeholder="Notice" onChange={(e)=>setFormValue({...formValue,notice:e.target.value})} name="id"/>
      </Col>





    </Row>
    </Container>
        </Form>   
        </Modal.Body>

            <Modal.Footer>
            <Button variant="warning" onClick={closeHandle}>Cancel</Button>
            <Button variant="info" onClick={()=>{submitHandler();}}>Submit</Button>
        
        </Modal.Footer>

            </Modal>



    )
}

export default CreateForm;