import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux'
import {Modal,Form,Button,Row,Col,Container} from 'react-bootstrap';
import {create_publishers_Data} from '../actions/PublishersActions'

 
const CreateForm = (props) =>{
  const [formValue,setFormValue] = useState({});
    const {
        show,
        handleClose,
    }=props;
    const closeHandle=()=>{
      handleClose();
      resetFormValue();
      
    }
    const resetFormValue = () =>{
      setFormValue({});
    }
    const dispatch = useDispatch();

    const submitHandler = ( data) =>{

      dispatch(create_publishers_Data(data));
      resetFormValue();
      closeHandle();

    }






    return(
        <Modal 
            show={show} 
            onHide={closeHandle}
            size="lg"
            centered
            style={{backgroundColor:'rgba(0, 204, 255,0.7)'}}
            >
              <Modal.Header closeButton>
          <Modal.Title>Publisher</Modal.Title>
        </Modal.Header>
        <Modal.Body>  
        <Form>
          <Container>
          {console.log(formValue)}
         {/* {console.log(print)} */}
        <Row>
      
      <br/>
      <Col sm={6}>
      <Form.Label >
        <b>Name</b>
      </Form.Label>
      <Form.Control type="text" placeholder="name" onChange={(e)=>setFormValue({...formValue,name:e.target.value})} name="id"/>
      </Col><br/><br/><br/><br/>

      <Col sm={6}>
      <Form.Label >
        <b>Mobile No.</b>
      </Form.Label>
      <Form.Control type="text" placeholder="mobile no" onChange={(e)=>setFormValue({...formValue,mobile_no:e.target.value})} name="id"/>
      </Col><br/><br/><br/><br/>

      
      <Col sm={6}>
      <Form.Label >
        <b>Address</b>
      </Form.Label>
        <Form.Control type="text" placeholder="address"  onChange={(e)=>setFormValue({...formValue,address:e.target.value})}/>
        </Col><br/><br/><br/><br/>


        <Col sm={6}>
      <Form.Label >
        <b>Email Address</b>
      </Form.Label>
        <Form.Control type="text" placeholder="email_address"  onChange={(e)=>setFormValue({...formValue,email_address:e.target.value})}/>
        </Col><br/><br/><br/><br/>

        <Col sm={6}>
      <Form.Label >
        <b>Password</b>
      </Form.Label>
        <Form.Control type="password" placeholder="password"  onChange={(e)=>setFormValue({...formValue,password:e.target.value})}/>
        </Col><br/><br/><br/><br/>


        <Col sm={4}>
      <Form.Label >
      <b>MAC Address</b>
      </Form.Label>
        <Form.Control type="text" placeholder="mac address"  onChange={(e)=>setFormValue({...formValue,mac_address:e.target.value})}/></Col><br/><br/><br/><br/>

       <Col>
    <Form.Group  >
      <Form.Label >
        <b>Published Status</b>
      </Form.Label>
      <Col className="d-flex" onChange={(e)=>setFormValue({...formValue,status:e.target.value})} >
        <Form.Check
          type="radio"
          label="YES"
          name="formHorizontalRadios"
          id="formHorizontalRadios1"
          value="Y"
        /> &nbsp;&nbsp;
        <Form.Check
          type="radio"
          label="NO"
          name="formHorizontalRadios"
          id="formHorizontalRadios2"
          value="N"
        />
      </Col>
    </Form.Group>
    </Col>

    </Row>
    </Container>
        </Form>   
        </Modal.Body>

            <Modal.Footer>
            <Button variant="warning" onClick={closeHandle}>Cancel</Button>
            <Button variant="info" onClick={()=>submitHandler(formValue)}>Submit</Button>
        
        </Modal.Footer>

            </Modal>



    )
}

export default CreateForm;