import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux'
import {Modal,Form,Button,Row,Col,Container} from 'react-bootstrap';
import {create_notice_areas_Data} from '../actions/NoticeAreasActions'


 
const CreateForm = (props) =>{
  const [formValue,setFormValue] = useState({});
    const {
        show,
        handleClose,
    }=props;
    const closeHandle=()=>{
      handleClose();
      resetFormValue();
      
    }
    const resetFormValue = () =>{
      setFormValue({});
    }
    const dispatch = useDispatch();
  
    const submitHandler = ( data) =>{

      dispatch(create_notice_areas_Data(data));
      resetFormValue();
      closeHandle();

    }






    return(
        <Modal 
            show={show} 
            onHide={closeHandle}
            size="lg"
            centered
            style={{backgroundColor:'rgba(0, 204, 255,0.7)'}}
            >
              <Modal.Header closeButton>
          <Modal.Title>Notice Area </Modal.Title>
        </Modal.Header>
        <Modal.Body>  
        <Form>
          <Container>
          {console.log(formValue)}
         {/* {console.log(print)} */}
        <Row>
   
      <br/>
      <Col sm={6}>
      <Form.Label >
        <b>Country</b>
      </Form.Label>
      <Form.Control type="text" placeholder="Country" onChange={(e)=>setFormValue({...formValue,country:e.target.value})} name="id"/>
      </Col><br/><br/><br/><br/>
      <Col sm={6}>
      <Form.Label >
        <b>Area</b>
      </Form.Label>
        <Form.Control type="text" placeholder="Area"  onChange={(e)=>setFormValue({...formValue,area:e.target.value})}/></Col><br/><br/><br/><br/>
    
       

    </Row>
    </Container>
        </Form>   
        </Modal.Body>

            <Modal.Footer>
            <Button variant="warning" onClick={closeHandle}>Cancel</Button>
            <Button variant="info" onClick={()=>submitHandler(formValue)}>Submit</Button>
        
        </Modal.Footer>

            </Modal>



    )
}

export default CreateForm;