import React from 'react';
import {ListGroup} from 'react-bootstrap';
import {Link} from "react-router-dom";
import { MdDashboard } from "react-icons/md";
import { ImHome } from "react-icons/im";
import { GiThorHammer } from "react-icons/gi";
import { GrChapterAdd } from "react-icons/gr";
import { ImList } from "react-icons/im";
import { BiCalendarEvent } from "react-icons/bi";



import { GrArticle } from "react-icons/gr";











const menuOpen={
    // overflow:'visible',
    backgroundColor:'DarkCyan',
    height:'100vh',
    width:'20em',
    transition:'margin 0.5s'
    

   
  }
  
  const menuClose={
    // overflow:'hidden',
    backgroundColor:'DarkCyan',
    height:'100vh',
    width:'20em',
    marginLeft:'-20em',
    transition:'margin 0.5s'

    

    
  }

const SideBar = (props) =>{

   const { open,} = props
    return(
        
        <div style={open?menuOpen:menuClose}  className="mr-2">
        
        <ListGroup >
        <ListGroup.Item ><h2 ><MdDashboard/><span style={{verticalAlign:'middle'}}>Dashboard</span></h2> </ListGroup.Item>
        <Link to="/" style={{textDecoration:'none',color:'black'}}><ListGroup.Item ><ImHome/><span style={{verticalAlign:'middle'}}> Home</span> </ListGroup.Item></Link>
        <Link to="/publishers" style={{textDecoration:'none',color:'black'}}><ListGroup.Item ><GiThorHammer/><span style={{verticalAlign:'middle'}}> Publishers</span></ListGroup.Item></Link>
        <Link to="/noticeareas" style={{textDecoration:'none',color:'black'}}><ListGroup.Item ><GrChapterAdd/><span style={{verticalAlign:'middle'}}> Notice Areas</span></ListGroup.Item></Link>
        <Link to="/noticecategories" style={{textDecoration:'none',color:'black'}}><ListGroup.Item ><ImList/><span style={{verticalAlign:'middle'}}> Notice Categories</span></ListGroup.Item></Link>
        {/* <Link to="/noticecategories" style={{textDecoration:'none',color:'black'}}><ListGroup.Item ><GrArticle/><span style={{verticalAlign:'middle'}}> Notice Categories</span></ListGroup.Item></Link> */}
        <Link to="/notices" style={{textDecoration:'none',color:'black'}}><ListGroup.Item ><GrArticle/><span style={{verticalAlign:'middle'}}> Notices</span></ListGroup.Item></Link>
        <Link to="/events" style={{textDecoration:'none',color:'black'}}><ListGroup.Item ><BiCalendarEvent/><span style={{verticalAlign:'middle'}}> Events</span></ListGroup.Item></Link>

        </ListGroup>
        
        </div>

    )
}

export default SideBar;