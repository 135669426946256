import React from 'react';
import TableComponent from './TableComponent';


const Events = () =>{
    return(
        <>
                <TableComponent></TableComponent>

        </>
    )
}

export default Events;