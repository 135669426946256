import axios from 'axios';
import {NOTICES_RECIEVE} from '../constants/NoticesConstants'


export const get_notices_data= ()=>async (dispatch)=>{

    axios.get('http://noticeboard.clonestudiobd.com/api/notices')
    .then(response=>{
    //   setData(response.data)
    const data = response.data;
      console.log('get_notices_data',data)
      dispatch({
        type:NOTICES_RECIEVE,
        payload:data
      })
    })
    .catch(error=>{
      console.log(error)
    })



}

export const create_notices_Data = (data) =>async (dispatch)=>{

  axios.post('http://noticeboard.clonestudiobd.com/api/notices',data)
  .then(response=>{
  //   setData(response.data)
  // const data = response.data;
    console.log('create_notice_areas_Data',response)
    dispatch(get_notices_data());
    
  })
  .catch(error=>{
    console.log(error.data)
  })

}


export const update_notices_Data = (data,id) =>async (dispatch)=>{

  axios.post(`http://noticeboard.clonestudiobd.com/api/notices/${id}`,data)
  .then(response=>{
  //   setData(response.data)
  // const data = response.data;
    console.log('update_notices_Data',response)
    dispatch(get_notices_data());
    
  })
  .catch(error=>{
    console.log(error)
  })

}


export const delete_notices_Data = (id) =>async (dispatch)=>{

  axios.delete(`http://noticeboard.clonestudiobd.com/api/notices/${id}`)
  .then(response=>{
  //   setData(response.data)
  // const data = response.data;
    console.log('delete_notice_categories_Data',response)
    dispatch(get_notices_data());
    
  })
  .catch(error=>{
    console.log(error)
  })
  console.log(id);

}




