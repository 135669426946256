import { createStore,combineReducers,applyMiddleware,compose } from 'redux';
// import TestReducer from '../reducers/TestReducer';
import publishers from '../reducers/PublishersReducers'
import notice_areas from '../reducers/NoticeAreasReducer'
import notice_categories from '../reducers/NoticeCategoriesReducer'
import notices from '../reducers/NoticesReducer'
import events from '../reducers/EventsReducer'






import AuthenticationReducer from '../reducers/AuthenticationReducer'



import thunk from 'redux-thunk';


const rootReducer = combineReducers(
    {
        isAuth:AuthenticationReducer,
        publisher:publishers,
        areas:notice_areas,
        categories:notice_categories,
        notice:notices,
        events:events
    }
        );

const middleware = [thunk]

const InitialState = {
    isAuth:[],
    publisher:[],
    areas:[],
    categories:[],
    notice:[],
    events:[]  
}

const store =createStore(rootReducer,InitialState,compose(applyMiddleware(...middleware)));

export default store;