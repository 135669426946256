const AuthenticationReducer = (state={},{type,payload}) =>{

    switch(type){
        case 'AUTH_UPDATE':
            return {...state,auth:payload}
        case 'ADD_USER':
            return {...state,user:payload}
        default:
            if(localStorage.getItem("token"))
            {return {...state,auth:true}}
            else return {...state}
    }


}

export default AuthenticationReducer;