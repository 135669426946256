import axios from 'axios';
import {NOTICE_AREAS_CONSTANTS} from '../constants/NoticeAreasConstants'


export const get_notice_areas_data= ()=>async (dispatch)=>{

    axios.get('http://noticeboard.clonestudiobd.com/api/noticeareas')
    .then(response=>{
    //   setData(response.data)
    const data = response.data;
      console.log('get_notice_areas_data',data)
      dispatch({
        type:NOTICE_AREAS_CONSTANTS,
        payload:data
      })
    })
    .catch(error=>{
      console.log(error)
    })

}


export const create_notice_areas_Data = (data) =>async (dispatch)=>{

  axios.post('http://noticeboard.clonestudiobd.com/api/noticeareas',data)
  .then(response=>{
  //   setData(response.data)
  // const data = response.data;
    console.log('create_notice_areas_Data',response)
    dispatch(get_notice_areas_data());
    
  })
  .catch(error=>{
    console.log(error)
  })

}


export const update_notice_areas_Data = (data,id) =>async (dispatch)=>{

  axios.put(`http://noticeboard.clonestudiobd.com/api/noticeareas/${id}`,data)
  .then(response=>{
  //   setData(response.data)
  // const data = response.data;
    console.log('update_notice_areas_Data',response)
    dispatch(get_notice_areas_data());
    
  })
  .catch(error=>{
    console.log(error)
  })

}


export const delete_notice_areas_Data = (id) =>async (dispatch)=>{

  axios.delete(`http://noticeboard.clonestudiobd.com/api/noticeareas/${id}`)
  .then(response=>{
  //   setData(response.data)
  // const data = response.data;
    console.log('delete_notice_areas_Data',response)
    dispatch(get_notice_areas_data());
    
  })
  .catch(error=>{
    console.log(error)
  })
  console.log(id);

}