import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux'
import {Modal,Form,Button,Row,Col,Container} from 'react-bootstrap';
import {update_notice_categories_Data} from '../actions/NoticeCategoriesActions'
// import {update_publishers_Data} from '../actions/'




 
const EditForm = (props) =>{
  
  const [noticeCategory,setNoticeCategory] = useState("");
  const [noticeIcon,setNoticeIcon] = useState(null);
  const [noticeRate,setNoticeRate] = useState("");
  const [perDayRate,setPerDayRate] = useState("");
  const [printSerial,setPrintSerial] = useState("");
  const [publishedStatus,setPublishedStatus] = useState("");
  const formData = new FormData();

    const {
        show,
        handleClose,
        editId,
    }=props;
    const closeHandle=()=>{
      handleClose();
      resetFormValue();
      
    }
    const resetFormValue = () =>{
      setNoticeCategory("");
      setNoticeIcon(null);
      setNoticeRate("");
      setPerDayRate("");
      setPrintSerial("");
      setPublishedStatus("");
    }
    const dispatch = useDispatch();
  
    
    
    const formSubmitHandler= () =>{
      //               <td style={{padding:'2px'}}>{item.notice_category}</td>
      //               <td style={{padding:'2px'}}>{item.notice_icon}</td>
      //               <td style={{padding:'2px'}}>{item.notice_rate}</td>
      //               <td style={{padding:'2px'}}>{item.per_day_rate}</td>
      //               <td style={{padding:'2px'}}>{item.print_serial}</td>
      //               <td style={{padding:'2px'}}>{item.published_status}</td>
                    
        console.log('From Form Submit Handler');
  
  
        if(noticeIcon!==null){
        formData.append("notice_icon",noticeIcon,noticeIcon.name);}
        if(noticeCategory!=""){
        formData.append("notice_category",noticeCategory);
          }
        if(noticeRate!=""){
          formData.append("notice_rate",noticeRate);
          }
        if(perDayRate!=""){
          formData.append("per_day_rate",perDayRate);
          }
        if(printSerial!=""){
          formData.append("print_serial",printSerial);
          }
        if(publishedStatus!=""){
          formData.append("published_status",publishedStatus);
          }
          dispatch(update_notice_categories_Data(formData,editId.id));
          resetFormValue();
          closeHandle();
        
      }






    return(
        <Modal 
            show={show} 
            onHide={closeHandle}
            size="lg"
            centered
            style={{backgroundColor:'rgba(0, 204, 255,0.7)'}}
            >
              <Modal.Header closeButton>
          <Modal.Title>Notice Categories</Modal.Title>
        </Modal.Header>
        <Modal.Body>  
        <Form >
          <Container>
          {/* {console.log(formValue)} */}
         {/* {console.log(print)} */}
        <Row>

     
        <Col sm={6}>
      <Form.Label >
        <b>Category Name</b>
      </Form.Label>
      
      <Form.Control type="text" defaultValue={editId?editId.notice_category:null} placeholder="Category Name"  onChange={(e)=>{setNoticeCategory(e.target.value);console.log(noticeCategory)}} name="category_name"/>
      </Col><br/><br/><br/><br/>


      <Col sm={6}>
      <Form.Label >
        <b>Category Icon</b>
      </Form.Label>
        <Form.Group>
      <Form.File id="categor_icon" onChange={(e)=>{setNoticeIcon(e.target.files[0])}} />
        </Form.Group>
        </Col><br/><br/><br/><br/>



      

      <Col sm={6}>
      <Form.Label >
        <b>Notice Rate</b>
      </Form.Label>
      <Form.Control type="text" defaultValue={editId?editId.notice_rate:null} placeholder="notice rate"  onChange={(e)=>{setNoticeRate(e.target.value);console.log(noticeRate)}} name="category_name"/>
      </Col><br/><br/><br/><br/>


      <Col sm={6}>
      <Form.Label >
        <b>Per Day Rate</b>
      </Form.Label>
      <Form.Control type="text" defaultValue={editId?editId.per_day_rate:null} placeholder="per day rate"  onChange={(e)=>{setPerDayRate(e.target.value);console.log(perDayRate)}} name="category_name"/>
      </Col><br/><br/><br/><br/>

      <Col sm={6}>
      <Form.Label >
        <b>Print Serial</b>
      </Form.Label>
      <Form.Control type="text" defaultValue={editId?editId.print_serial:null} placeholder="print serial"  onChange={(e)=>{setPrintSerial(e.target.value);console.log(printSerial)}} name="category_name"/>
      </Col><br/><br/><br/><br/>



      <Col>
    <Form.Group  >
      <Form.Label >
        <b>Published Status</b>
      </Form.Label>
      
      <Col className="d-flex"  onChange={(e)=>{setPublishedStatus(e.target.value)}} >
         
        {editId===null?null:((publishedStatus===""?editId.published_status:publishedStatus)==="Y"?
        ( <Form.Check
          type="radio"
          label="YES"
          name="formHorizontalRadios"
          id="formHorizontalRadios1"
          value="Y"
          checked
        /> ):( <Form.Check
          type="radio"
          label="YES"
          name="formHorizontalRadios"
          id="formHorizontalRadios1"
          value="Y"
        /> ))}
         &nbsp;&nbsp;
         {editId===null?null:((publishedStatus===""?editId.published_status:publishedStatus)==="N"?
        ( <Form.Check
          type="radio"
          label="NO"
          name="formHorizontalRadios"
          id="formHorizontalRadios1"
          value="N"
          checked
        /> ):( 
        <Form.Check
          type="radio"
          label="NO"
          name="formHorizontalRadios"
          id="formHorizontalRadios1"
          value="N"
          
        />
         ))}
        
      </Col>
    </Form.Group>
    </Col>
   
    
    </Row>
    </Container>

        </Form>   
        </Modal.Body>

            <Modal.Footer>
            <Button variant="warning" onClick={closeHandle}>Cancel</Button>
      <Button variant="info" type="submit" onClick={formSubmitHandler}>Submit</Button>

        </Modal.Footer>
        
       {console.log('Edit ID:',editId)}
            </Modal>



    )
}

export default EditForm;