
const notices = (state={},{type,payload}) =>{

    switch(type){
        case 'NOTICES_RECIEVE':
            return {...state,notices:payload}
        default:
            return {...state}
    }

}

export default notices;