import axios from 'axios';
import {PUBLISHERS_RECIEVE} from '../constants/PublisherConstants'


export const get_publishers_data= ()=>async (dispatch)=>{

    axios.get('http://noticeboard.clonestudiobd.com/api/publishers')
    .then(response=>{
    //   setData(response.data)
    const data = response.data;
      console.log('get_publishers_data',data)
      dispatch({
        type:PUBLISHERS_RECIEVE,
        payload:data
      })
    })
    .catch(error=>{
      console.log(error)
    })



}

export const create_publishers_Data = (data) =>async (dispatch)=>{

  axios.post('http://noticeboard.clonestudiobd.com/api/publishers',data)
  .then(response=>{
  //   setData(response.data)
  // const data = response.data;
    console.log('create_publishers_Data',response)
    dispatch(get_publishers_data());
    
  })
  .catch(error=>{
    console.log(error)
  })

}


export const update_publishers_Data = (data,id) =>async (dispatch)=>{

  axios.put(`http://noticeboard.clonestudiobd.com/api/publishers/${id}`,data)
  .then(response=>{
  //   setData(response.data)
  // const data = response.data;
    console.log('update_publishers_Data',response)
    dispatch(get_publishers_data());
    
  })
  .catch(error=>{
    console.log(error)
  })

}


export const delete_publishers_Data = (id) =>async (dispatch)=>{

  axios.delete(`http://noticeboard.clonestudiobd.com/api/publishers/${id}`)
  .then(response=>{
  //   setData(response.data)
  // const data = response.data;
    console.log('delete_publishers_Data',response)
    dispatch(get_publishers_data());
    
  })
  .catch(error=>{
    console.log(error)
  })
  console.log(id);

}






