import React from 'react';


const HomePage = () =>{

    return(
        <></>
    )
}

export default HomePage;