import {SIGN_IN} from '../constants/AuthenticationConstants';
import {AUTH_UPDATE} from '../constants/AuthenticationConstants';
import {ADD_USER} from '../constants/AuthenticationConstants';
import axios from 'axios';


export const sign_in =(creadential) => async (dispatch)=>{

    axios.post('http://compliance.clonestudiobd.com/api/auth/admin/login',creadential)
    .then(response=>{
        const data = response.data;
        console.log(data)
        localStorage.setItem("token",data.access_token);
        console.log(localStorage.getItem("token"))
        dispatch(
            {
                type:AUTH_UPDATE,
                payload:true

            }
            )
    })
    .catch(error=>{
        console.log(error)

    })

}

export const user_get =(token) => async (dispatch)=>{

    axios.post('http://compliance.clonestudiobd.com/api/auth/admin/me',{},
        {headers: {
        'Authorization': `bearer ${token}` 
        }})
    .then(response=>{
        const data = response.data;
        console.log(data)
        console.log("Prottoy1")
        dispatch(
            {
                type:ADD_USER,
                payload:data

            }
            );
            dispatch(
                {
                    type:AUTH_UPDATE,
                    payload:true
    
                }
                )
       
    })
    .catch(error=>{
        console.log(error.response.data)
        console.log("Prottoy2")

    })

}