import axios from 'axios';
import {NOTICE_CATEGORIES_RECIEVE} from '../constants/NotieceCategoriesConstants'


export const get_notice_categories_data= ()=>async (dispatch)=>{

    axios.get('http://noticeboard.clonestudiobd.com/api/noticecategories')
    .then(response=>{
    //   setData(response.data)
    const data = response.data;
      console.log('get_notice_categories_data',data)
      dispatch({
        type:NOTICE_CATEGORIES_RECIEVE,
        payload:data
      })
    })
    .catch(error=>{
      console.log(error)
    })



}


export const create_notice_categories_Data = (data) =>async (dispatch)=>{

  axios.post('http://noticeboard.clonestudiobd.com/api/noticecategories',data)
  .then(response=>{
  //   setData(response.data)
  // const data = response.data;
    console.log('create_notice_categories_Data',response)
    dispatch(get_notice_categories_data());
    
  })
  .catch(error=>{
    console.log(error)
  })

}


export const update_notice_categories_Data = (data,id) =>async (dispatch)=>{

  axios.post(`http://noticeboard.clonestudiobd.com/api/noticecategories/${id}`,data)
  .then(response=>{
  //   setData(response.data)
  // const data = response.data;
    console.log('update_notice_categories_Data',response)
    dispatch(get_notice_categories_data());
    
  })
  .catch(error=>{
    console.log(error)
  })

}


export const delete_notice_categories_Data = (id) =>async (dispatch)=>{

  axios.delete(`http://noticeboard.clonestudiobd.com/api/noticecategories/${id}`)
  .then(response=>{
  //   setData(response.data)
  // const data = response.data;
    console.log('delete_notice_categories_Data',response)
    dispatch(get_notice_categories_data());
    
  })
  .catch(error=>{
    console.log(error)
  })
  console.log(id);

}



