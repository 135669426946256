import React from 'react';
import TableComponent from './TableComponent';


const NoticeCategories = () =>{
    return(
        <>
                <TableComponent></TableComponent>

        </>
    )
}

export default NoticeCategories;