import axios from 'axios'
import {EVENTS_RECIEVE} from '../constants/EventsConstants'

export const get_events_data= ()=>async (dispatch)=>{

    axios.get('http://noticeboard.clonestudiobd.com/api/events')
    .then(response=>{
    //   setData(response.data)
    const data = response.data;
      console.log('get_events_data',data)
      dispatch({
        type:EVENTS_RECIEVE,
        payload:data
      })
    })
    .catch(error=>{
      console.log(error)
    })



}