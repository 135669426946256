import React,{useState} from 'react';
import {Modal,Form,Button,Row,Col,Container} from 'react-bootstrap'
import Auth from './Auth';
import {useDispatch,useSelector} from 'react-redux';
import {sign_in} from '../actions/AuthenticationActions'




const LoginPage = () =>{

    const [formValue,setFormValue] = useState({});
    const dispatch = useDispatch();
   
    

    return(
        <div>
            <div  style={{
                backgroundImage:"linear-gradient(to right, #0099F7 , #A43931)" ,

                height:"100vh"}}>
                    <Container>
            <Row className="justify-content-md-center m-0">
            <Col sm={4}  style={{
                color:"whitesmoke",
                borderRadius:"25px",
                marginTop:"8em",
                height:"20em",
                border:"2px salmon solid",
                paddingTop:"2em"
                }}>
            <Col >
                <h4 style={{color:"whitesmoke"}}>Sign In</h4>
            </Col>

                <hr style={{backgroundColor:"whitesmoke"}}/>
                <Form  onSubmit={(e)=>{e.preventDefault();dispatch(sign_in(formValue))}}>
            <Col >
                <Form.Control type="text" placeholder="username"  name="id" onChange={(e)=>{setFormValue({...formValue,user_id:e.target.value})}}/>
            </Col>
                <br/>

            <Col>
                <Form.Control type="password" placeholder="password"  name="id" onChange={(e)=>{setFormValue({...formValue,password:e.target.value})}}/>
            </Col>
                <br/>
      
            <Col>
                <input 
                style={{
                    display:"block",
                    textAlign:"center",
                    backgroundColor:"#4DA0B0",
                    padding:".3em",
                    border:"white .5px solid",
                    borderRadius:"5px",
                    cursor:'pointer',
                    width:"100%",
                    }}
                    // onClick={()=>dispatch(sign_in(formValue))}
                    type="submit"
                    value = "sign in"
                    />
                        {/* Sign in
                        </span>
                        */}
                        
            </Col>
            </Form>
            </Col>
        </Row>
        </Container>
        {console.log(Auth.isAuth())}
        </div>
        </div>
        
    )
}
export default LoginPage;