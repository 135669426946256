const events = (state={},{type,payload}) =>{

    switch(type){
        case 'EVENTS_RECIEVE':
            return {...state,events:payload}
        default:
            return {...state}
    }

}

export default events;