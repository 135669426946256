import React from 'react'
import {Route,Redirect} from 'react-router-dom'
import Auth from './Auth';
import {useSelector} from 'react-redux';



function PrivateRoute({ children, ...rest }) {
   const value = false;
   const auth = useSelector((state)=>state.isAuth.auth)

    return (
      <Route {...rest} render={()=>auth?children:<Redirect to="/login"/>} />
    );
  }

  export default PrivateRoute;