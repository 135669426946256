import React,{ useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux'
import {Table,Button,Form,OverlayTrigger,Tooltip,Pagination} from 'react-bootstrap';
import CreateForm from './CreacteForm';
import EditForm from './EditForm';
import {get_events_data} from '../actions/EventsActions'
import { AiFillEdit } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import { AiOutlineDownload } from "react-icons/ai";
import DeleteForm from './DeleteForm';
// import DownloadPdf from './DownloadPdf'
import { jsPDF } from "jspdf";




const TableComponent = ()=> {
  let i= 0;
  let j=0;
  const [start,setStart] = useState(0);
  const [perPage,setPerPage] = useState(20);
  const [editId,setEditId] = useState(null);
  // const [data,setData] = useState([]);
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const handleEditClose = () => setEdit(false);
  const handleEditShow = () => setEdit(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showDelete, setShowDelete] = useState(false);

  const handleDeleteClose = () => setShowDelete(false);
  const handleDeleteShow = () => setShowDelete(true);
  const [filterValue,setFilterValue] = useState({value:""});


  const dispatch = useDispatch();
  const tableData = useSelector((state)=>state.events.events)
  const fetchData = tableData;
  console.log('from table component',fetchData);
 

  const datafilter=(data)=>{
    return data.filter((me)=>{
      console.log("from filter function",me.id.toString(10).toLowerCase())
      console.log("from filter done");
      console.log(filterValue);
      return filterValue.value===""?me:(me.id.toString(10).toLowerCase().includes(filterValue.value.toLowerCase()));
    });
  
   }

   const clickHandler = (data) =>{
    
    console.log(data);
    var y = 34;
    const doc = new jsPDF();
    var splitTitle = doc.splitTextToSize( data.content, 250);
    console.log(splitTitle);
    doc.setFontSize("13");
    doc.text(`Name : ${data.name}`, 10, 10);
    doc.text(`Category :  ${data.category}`, 10, 18);
    doc.text(`Content :`, 10, 26);
    doc.setFontSize("12");
    for (var i = 0; i < splitTitle.length; i++) {                
      if (y > 280) {
          y = 10;
          doc.addPage();
      }
      doc.text(10, y, splitTitle[i]);
      y = y + 7;
  }
    // doc.text(`${data.content}`, 30, 34);

    // doc.text("Hello world!", 10, 10);
    // // doc.save("a4.pdf");
    doc.output('dataurlnewwindow');


    }






  useEffect(()=>{
    dispatch(get_events_data());  
  },[])
const editHandler=(id)=>{
  handleEditShow();
  setEditId(id);
}
const deleteHandler=(id)=>{
  
  handleDeleteShow();
  setEditId(id);
}

    return (
        <div style={{width:'90%',margin:'auto',padding:'2em',paddingBottom:"3em",border:'1px solid lightblue',borderRadius:'12px'}} className="mt-5" >
           <Pagination >
        <Pagination.First onClick={()=>setStart(0)} />
          <Pagination.Prev />
  
          {
           
           fetchData?(
             fetchData.slice(0,(Math.ceil(fetchData.length/perPage))).map(item=>{

            var value = (j)*perPage;
            var main = ++j;
            return <Pagination.Item onClick={()=>setStart(value)}>{main}</Pagination.Item>
            
          })):null
       
          }
          <Pagination.Next />
          <Pagination.Last onClick={()=>setStart((j-1)*perPage)} />
          </Pagination>


             <Form.Control className="float-left mb-2" style={{width:'50%'}} type="email" placeholder="Search" onClick={()=>setStart(0)} onChange={(e)=>{setFilterValue({...filterValue,value:e.target.value});console.log(filterValue)}}/>
            {/* <Button className="float-right mb-2"  onClick={handleShow} >Add New</Button> */}
         
            <Table striped bordered hover responsive="lg"  >
            <thead style={{backgroundColor:'lightblue'}}>
            <tr>
              <th style={{padding:'2px'}}>Name</th>
                <th style={{padding:'2px'}}>Gender</th>
                <th style={{padding:'2px'}}>Age</th>
                <th style={{padding:'2px'}}>Mobile No.</th>
                <th style={{padding:'2px'}}>Category</th>
                <th style={{padding:'2px'}}>Content</th>
                <th style={{padding:'2px'}}>Actions</th>

            </tr>
            </thead>
            <tbody>
                  {/* {console.log('inrender',data)} */}
                  {fetchData?(datafilter(fetchData,"prottoy").slice(start,start+perPage).map(item=>(
              <tr key={item.id} style={{border:'none',}}>
                  <td style={{padding:'2px',paddingLeft:'5px',whiteSpace:'nowrap',border:'0px solid black'}}>{item.name}</td>
                  {/* <td style={{padding:'2px'}}>{
                  item.notice_icon!=""?(
                    <img src={item.notice_icon} height="40px" width="40px"></img>
                  ):"no img"
                  }</td> */}
                  <td style={{padding:'2px',border:'0px solid black'}}>{item.gender}</td>
                  <td style={{padding:'2px',border:'0px solid black'}}>{item.age}</td>
                  <td style={{padding:'2px',border:'0px solid black'}}>{item.mobile_no}</td>
                  <td style={{padding:'2px',border:'0px solid black'}}>{item.category}</td>
                  <td style={{padding:'2px',display:'block',border:'0px solid black',height:'1.5em',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'normal'}}>{item.content}</td>
                  <td style={{padding:'2px'}}>
                    
                  <OverlayTrigger
                  key="bottom"
                  placement="bottom"
                  overlay={
                <Tooltip id={`tooltip-bottom`}>
                  <font size="2">DOWNLOAD</font>
                </Tooltip>
                  }
                  >
                <AiOutlineDownload style={{color:'RoyalBlue',cursor:'pointer'}} onClick={()=>{clickHandler(item)}} />

              </OverlayTrigger>
              &nbsp; &nbsp;
                  <OverlayTrigger
                  key="bottom"
                  placement="bottom"
                  overlay={
                <Tooltip id={`tooltip-bottom`}>
                  <font size="2">DELETE</font>
                </Tooltip>
                  }
                  >
              <AiFillDelete style={{color:'Tomato',cursor:'pointer'}} />
              {/* <AiFillDelete style={{color:'Tomato',cursor:'pointer'}} onClick={()=>{deleteHandler(item)}} /> */}

              </OverlayTrigger>
                </td>
                  {console.log('rendered times:',item.id)}
              </tr>
              ))):"No Data Found"}
              </tbody>
            </Table>
        
            <Pagination className="float-right mt-0">
        <Pagination.First onClick={()=>setStart(0)} />
          <Pagination.Prev />
  
          {
           
           fetchData?(
             fetchData.slice(0,(Math.ceil(fetchData.length/perPage))).map(item=>{

            var value = (i)*perPage;
            var main = ++i;
            return <Pagination.Item onClick={()=>setStart(value)}>{main}</Pagination.Item>
            
          })):null
       
          }
  {/* <Pagination.Item onClick={()=>setStart(0)}>{1}</Pagination.Item> */}
  {/* <Pagination.Item onClick={()=>setStart(perPage)}>{2}</Pagination.Item>
  <Pagination.Item>{2}</Pagination.Item>
  <Pagination.Item>{3}</Pagination.Item> */}
 


  <Pagination.Next />
  <Pagination.Last />
</Pagination>
            <CreateForm 
            show = {show}
            setShow = {setShow}
            handleClose = {handleClose}
            handleShow = {handleShow}
            
            />
            <EditForm
            show = {edit}
            setShow = {setEdit}
            handleClose = {handleEditClose}
            handleShow = {handleEditShow}
            editId={editId}
            />
               <DeleteForm
            show = {showDelete}
            setShow = {setShowDelete}
            handleClose = {handleDeleteClose}
            handleShow = {handleDeleteShow}
            editId={editId}
            />
            {/* <DownloadPdf/> */}
          </div>

    )

}

export default TableComponent;