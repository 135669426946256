
const notice_areas = (state={},{type,payload}) =>{

    switch(type){
        case 'NOTICE_AREAS_CONSTANTS':
            return {...state,notice_areas:payload}
        default:
            return {...state}
    }

}

export default notice_areas;